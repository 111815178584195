import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import PrivacyPolicy from './Components/PrivacyPolicy';
import TermsConditions from './Components/TermsConditions';
import WhatsAppIcon from './Components/WhatsAppIcon';
import Footer from './Components/Footer';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} key="home" /> 
        <Route path="/privacy" element={<PrivacyPolicy />} key="privacy" />
        <Route path="/terms" element={<TermsConditions />} key="terms" />
      </Routes>
      <Footer />
      <WhatsAppIcon/>
    </Router>
  );
}

export default App;
