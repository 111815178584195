import React from 'react';
import decoration from '../Assests/images/decoration.jpg';
import catering from '../Assests/images/catering.jpeg';
import venueBooking from '../Assests/images/banquet-hall.jpeg';
import photography from '../Assests/images/photography.jpg';
import makeup from '../Assests/images/makeup.jpg';
import dj from '../Assests/images/dj.jpg';
import carRental from '../Assests/images/car-rental.jpg';
import gifts from '../Assests/images/gifts.jpeg';
import flowerandgarlands from '../Assests/images/flowers.jpg';

const Services = () => {
  return (
    <section id="services">
      <div className="relative overflow-hidden mt-32 pb-32 space-y-12 ">
        <div className="max-w-[1240px] mx-auto px-6">
          <h1 className="text-4xl md:text-5xl font-bold tracking-tight text-amber-400">
            Our Services
          </h1>
          <p className="mt-4 text-md md:text-lg text-gray-300">
            EventBee offers a wide range of services to make your event unforgettable.
          </p>
        </div>
        <div className="mx-auto max-w-[1240px] px-6 lg:px-8 hidden md:block">
          <div className="grid lg:grid-cols-2 gap-6 items-center my-8">
            <div>
              <h2 className="text-3xl font-bold text-amber-400">Decoration</h2>
              <p className="mt-1 text-gray-300 text-md">
                We provide stunning decoration services to make your event
                aesthetically pleasing and memorable.
              </p>
              <div class="mt-3 md:mt-6">
                <a class="inline-flex rounded-lg bg-amber-400 px-4 py-1.5 text-base font-semibold leading-7 text-black shadow-lg shadow-amber-700  hover:bg-amber-600" href="https://api.whatsapp.com/send/?phone=8682960528&amp;text&amp;type=phone_number&amp;app_absent=0" target="_blank" rel="noreferrer">Book Now</a>
              </div>
            </div>
            <img
              src={decoration}
              alt="Decoration"
              className="w-full h-[300px] object-cover rounded-lg shadow-lg"
            />
          </div>

          <div className="grid lg:grid-cols-2 gap-6 items-center my-8">
            <img
              src={catering}
              alt="Catering"
              className="w-full h-[300px] object-cover rounded-lg shadow-lg"
            />
            <div>
              <h2 className="text-3xl font-bold text-amber-400">Catering</h2>
              <p className="mt-1 text-gray-300 text-md">
                Our catering services offer a wide range of delicious cuisines to
                delight your guests.
              </p>
              <div class="mt-3 md:mt-6">
                <a class="inline-flex rounded-lg bg-amber-400 px-4 py-1.5 text-base font-semibold leading-7 text-black shadow-lg shadow-amber-700  hover:bg-amber-600" href="https://api.whatsapp.com/send/?phone=8682960528&amp;text&amp;type=phone_number&amp;app_absent=0" target="_blank" rel="noreferrer">Book Now</a>
              </div>
            </div>
          </div>

          <div className="grid lg:grid-cols-2 gap-6 items-center my-8">
            <div>
              <h2 className="text-3xl font-bold text-amber-400">Venue Booking</h2>
              <p className="mt-1 text-gray-300 text-md">
                We help you find and book the perfect venue for your event,
                ensuring it meets all your requirements.
              </p>
              <div class="mt-3 md:mt-6">
                <a class="inline-flex rounded-lg bg-amber-400 px-4 py-1.5 text-base font-semibold leading-7 text-black shadow-lg shadow-amber-700  hover:bg-amber-600" href="https://api.whatsapp.com/send/?phone=8682960528&amp;text&amp;type=phone_number&amp;app_absent=0" target="_blank" rel="noreferrer">Book Now</a>
              </div>
            </div>
            <img
              src={venueBooking}
              alt="Venue Booking"
              className="w-full h-[300px] object-cover rounded-lg shadow-lg"
            />
          </div>

          <div className="grid lg:grid-cols-2 gap-6 items-center my-8">

            <img
              src={photography}
              alt="Photography"
              className="w-full h-[300px] object-cover rounded-lg shadow-lg"
            />
            <div>
              <h2 className="text-3xl font-bold text-amber-400">Photography</h2>
              <p className="mt-1 text-gray-300 text-md">
                Capture your precious moments with our professional photography
                services.
              </p>
              <div class="mt-3 md:mt-6">
                <a class="inline-flex rounded-lg bg-amber-400 px-4 py-1.5 text-base font-semibold leading-7 text-black shadow-lg shadow-amber-700  hover:bg-amber-600" href="https://api.whatsapp.com/send/?phone=8682960528&amp;text&amp;type=phone_number&amp;app_absent=0" target="_blank" rel="noreferrer">Book Now</a>
              </div>
            </div>
          </div>

          <div className="grid lg:grid-cols-2 gap-6 items-center my-8">
            <div>
              <h2 className="text-3xl font-bold text-amber-400">Makeup Artists</h2>
              <p className="mt-1 text-gray-300 text-md">
                Our talented makeup artists ensure you look your best for any event.
              </p>
              <div class="mt-3 md:mt-6">
                <a class="inline-flex rounded-lg bg-amber-400 px-4 py-1.5 text-base font-semibold leading-7 text-black shadow-lg shadow-amber-700  hover:bg-amber-600" href="https://api.whatsapp.com/send/?phone=8682960528&amp;text&amp;type=phone_number&amp;app_absent=0" target="_blank" rel="noreferrer">Book Now</a>
              </div>
            </div>
            <img
              src={makeup}
              alt="Makeup Artists"
              className="w-full h-[300px] object-cover rounded-lg shadow-lg"
            />
          </div>

          <div className="grid lg:grid-cols-2 gap-6 items-center my-8">
            <img
              src={dj}
              alt="DJ and Lights"
              className="w-full h-[300px] object-cover rounded-lg shadow-lg"
            />
            <div>
              <h2 className="text-3xl font-bold text-amber-400">DJ and Lights</h2>
              <p className="mt-1 text-gray-300 text-md">
                Create the perfect atmosphere with our DJ and lighting services.
              </p>
              <div class="mt-3 md:mt-6">
                <a class="inline-flex rounded-lg bg-amber-400 px-4 py-1.5 text-base font-semibold leading-7 text-black shadow-lg shadow-amber-700  hover:bg-amber-600" href="https://api.whatsapp.com/send/?phone=8682960528&amp;text&amp;type=phone_number&amp;app_absent=0" target="_blank" rel="noreferrer">Book Now</a>
              </div>
            </div>
          </div>

          <div className="grid lg:grid-cols-2 gap-6 items-center my-8">
            <div>
              <h2 className="text-3xl font-bold text-amber-400">Flower and Garlands</h2>
              <p className="mt-1 text-gray-300 text-md">
                Beautiful flower arrangements and garlands for all types of events.
              </p>
              <div class="mt-3 md:mt-6">
                <a class="inline-flex rounded-lg bg-amber-400 px-4 py-1.5 text-base font-semibold leading-7 text-black shadow-lg shadow-amber-700  hover:bg-amber-600" href="https://api.whatsapp.com/send/?phone=8682960528&amp;text&amp;type=phone_number&amp;app_absent=0" target="_blank" rel="noreferrer">Book Now</a>
              </div>
            </div>
            <img
              src={flowerandgarlands}
              alt="Flowers and Garlands"
              className="w-full h-[300px] object-cover rounded-lg shadow-lg"
            />
          </div>

          <div className="grid lg:grid-cols-2 gap-6 items-center my-8">
            <img
              src={carRental}
              alt="Car Rental"
              className="w-full h-[300px] object-cover rounded-lg shadow-lg"
            />
            <div>
              <h2 className="text-3xl font-bold text-amber-400">Car Rental</h2>
              <p className="mt-1 text-gray-300 text-md">
                Reliable car rental services for easy transportation during your event.
              </p>
              <div class="mt-3 md:mt-6">
                <a class="inline-flex rounded-lg bg-amber-400 px-4 py-1.5 text-base font-semibold leading-7 text-black shadow-lg shadow-amber-700  hover:bg-amber-600" href="https://api.whatsapp.com/send/?phone=8682960528&amp;text&amp;type=phone_number&amp;app_absent=0" target="_blank" rel="noreferrer">Book Now</a>
              </div>
            </div>
          </div>

          <div className="grid lg:grid-cols-2 gap-6 items-center my-8">
            <div>
              <h2 className="text-3xl font-bold text-amber-400">Customized Gifts</h2>
              <p className="mt-1 text-gray-300 text-md">
                We make customized gifts for your loved ones.
              </p>
              <div class="mt-3 md:mt-6">
                <a class="inline-flex rounded-lg bg-amber-400 px-4 py-1.5 text-base font-semibold leading-7 text-black shadow-lg shadow-amber-700  hover:bg-amber-600" href="https://api.whatsapp.com/send/?phone=8682960528&amp;text&amp;type=phone_number&amp;app_absent=0" target="_blank" rel="noreferrer">Book Now</a>
              </div>
            </div>
            <img
              src={gifts}
              alt="Customized Gifts"
              className="w-full h-[300px] object-cover rounded-lg shadow-lg"
            />
          </div>
        </div>
        {/* mobile view */}
        <div className='block md:hidden'>
          <div class="w-[400px] flex flex-col mt-6 mx-auto">
            <div class="shadow-sm rounded-xl bg-neutral-900 border-neutral-700 shadow-neutral-700/70">
              <img class="w-full h-auto rounded-t-xl" src={decoration} alt="Decoration" />
              <div class="p-4 md:p-5">
                <h3 class="text-xl font-bold text-amber-500 dark:text-white">Decoration</h3>
                <p class="mt-1 text-gray-300 text-md dark:text-neutral-400">We provide stunning decoration services to make your event aesthetically pleasing and memorable.</p>
                <a class="inline-flex rounded-lg bg-amber-400 px-4 py-1.5 mt-4 text-base font-semibold leading-7 text-black shadow-md shadow-amber-700  hover:bg-amber-600" href="https://api.whatsapp.com/send/?phone=8682960528&amp;text&amp;type=phone_number&amp;app_absent=0" target="_blank" rel="noreferrer">Book Now</a>
              </div>
            </div>
          </div>
          <div class="w-[400px] flex flex-col mt-6 mx-auto">
            <div class="shadow-sm rounded-xl bg-neutral-900 border-neutral-700 shadow-neutral-700/70">
              <img class="w-full h-auto rounded-t-xl" src={catering} alt="Catering" />
              <div class="p-4 md:p-5">
                <h3 class="text-xl font-bold text-amber-500 dark:text-white">Catering</h3>
                <p class="mt-1 text-gray-300 text-md dark:text-neutral-400">Our catering services offer a wide range of delicious cuisines to
                  delight your guests.</p>
                <a class="inline-flex rounded-lg bg-amber-400 px-4 py-1.5 mt-4 text-base font-semibold leading-7 text-black shadow-md shadow-amber-700  hover:bg-amber-600" href="https://api.whatsapp.com/send/?phone=8682960528&amp;text&amp;type=phone_number&amp;app_absent=0" target="_blank" rel="noreferrer">Book Now</a>
              </div>
            </div>
          </div>
          <div class="w-[400px] flex flex-col mt-6 mx-auto">
            <div class="shadow-sm rounded-xl bg-neutral-900 border-neutral-700 shadow-neutral-700/70">
              <img class="w-full h-auto rounded-t-xl" src={venueBooking} alt="Venue Booking" />
              <div class="p-4 md:p-5">
                <h3 class="text-xl font-bold text-amber-500 dark:text-white">Venue Booking</h3>
                <p class="mt-1 text-gray-300 text-md dark:text-neutral-400"> We help you find and book the perfect venue for your event,
                  ensuring it meets all your requirements.</p>
                <a class="inline-flex rounded-lg bg-amber-400 px-4 py-1.5 mt-4 text-base font-semibold leading-7 text-black shadow-md shadow-amber-700  hover:bg-amber-600" href="https://api.whatsapp.com/send/?phone=8682960528&amp;text&amp;type=phone_number&amp;app_absent=0" target="_blank" rel="noreferrer">Book Now</a>
              </div>
            </div>
          </div>
          <div class="w-[400px] flex flex-col mt-6 mx-auto">
            <div class="shadow-sm rounded-xl bg-neutral-900 border-neutral-700 shadow-neutral-700/70">
              <img class="w-full h-auto rounded-t-xl" src={photography} alt="Photography" />
              <div class="p-4 md:p-5">
                <h3 class="text-xl font-bold text-amber-500 dark:text-white">Photography</h3>
                <p class="mt-1 text-gray-300 text-md dark:text-neutral-400">Capture your precious moments with our professional photography
                  services.</p>
                <a class="inline-flex rounded-lg bg-amber-400 px-4 py-1.5 mt-4 text-base font-semibold leading-7 text-black shadow-md shadow-amber-700  hover:bg-amber-600" href="https://api.whatsapp.com/send/?phone=8682960528&amp;text&amp;type=phone_number&amp;app_absent=0" target="_blank" rel="noreferrer">Book Now</a>
              </div>
            </div>
          </div>
          <div class="w-[400px] flex flex-col mt-6 mx-auto">
            <div class="shadow-sm rounded-xl bg-neutral-900 border-neutral-700 shadow-neutral-700/70">
              <img class="w-full h-auto rounded-t-xl" src={makeup} alt="Makeup Artists" />
              <div class="p-4 md:p-5">
                <h3 class="text-xl font-bold text-amber-500 dark:text-white">Makeup Artists</h3>
                <p class="mt-1 text-gray-300 text-md dark:text-neutral-400"> Our talented makeup artists ensure you look your best for any event.</p>
                <a class="inline-flex rounded-lg bg-amber-400 px-4 py-1.5 mt-4 text-base font-semibold leading-7 text-black shadow-md shadow-amber-700  hover:bg-amber-600" href="https://api.whatsapp.com/send/?phone=8682960528&amp;text&amp;type=phone_number&amp;app_absent=0" target="_blank" rel="noreferrer">Book Now</a>
              </div>
            </div>
          </div>
          <div class="w-[400px] flex flex-col mt-6 mx-auto">
            <div class="shadow-sm rounded-xl bg-neutral-900 border-neutral-700 shadow-neutral-700/70">
              <img class="w-full h-auto rounded-t-xl" src={dj} alt="Dj and Lights" />
              <div class="p-4 md:p-5">
                <h3 class="text-xl font-bold text-amber-500 dark:text-white">DJ and Lights</h3>
                <p class="mt-1 text-gray-300 text-md dark:text-neutral-400">Create the perfect atmosphere with our DJ and lighting services.</p>
                <a class="inline-flex rounded-lg bg-amber-400 px-4 py-1.5 mt-4 text-base font-semibold leading-7 text-black shadow-md shadow-amber-700  hover:bg-amber-600" href="https://api.whatsapp.com/send/?phone=8682960528&amp;text&amp;type=phone_number&amp;app_absent=0" target="_blank" rel="noreferrer">Book Now</a>
              </div>
            </div>
          </div>
          <div class="w-[400px] flex flex-col mt-6 mx-auto">
            <div class="shadow-sm rounded-xl bg-neutral-900 border-neutral-700 shadow-neutral-700/70">
              <img class="w-full h-auto rounded-t-xl" src={flowerandgarlands} alt="Flower and Garlands" />
              <div class="p-4 md:p-5">
                <h3 class="text-xl font-bold text-amber-500 dark:text-white">Flower and Garlands</h3>
                <p class="mt-1 text-gray-300 text-md dark:text-neutral-400">Beautiful flower arrangements and garlands for all types of events.</p>
                <a class="inline-flex rounded-lg bg-amber-400 px-4 py-1.5 mt-4 text-base font-semibold leading-7 text-black shadow-md shadow-amber-700  hover:bg-amber-600" href="https://api.whatsapp.com/send/?phone=8682960528&amp;text&amp;type=phone_number&amp;app_absent=0" target="_blank" rel="noreferrer">Book Now</a>
              </div>
            </div>
          </div>
          <div class="w-[400px] flex flex-col mt-6 mx-auto">
            <div class="shadow-sm rounded-xl bg-neutral-900 border-neutral-700 shadow-neutral-700/70">
              <img class="w-full h-auto rounded-t-xl" src={carRental} alt="Car Rental" />
              <div class="p-4 md:p-5">
                <h3 class="text-xl font-bold text-amber-500 dark:text-white">Car Rental</h3>
                <p class="mt-1 text-gray-300 text-md dark:text-neutral-400">Reliable car rental services for easy transportation during your event.</p>
                <a class="inline-flex rounded-lg bg-amber-400 px-4 py-1.5 mt-4 text-base font-semibold leading-7 text-black shadow-md shadow-amber-700  hover:bg-amber-600" href="https://api.whatsapp.com/send/?phone=8682960528&amp;text&amp;type=phone_number&amp;app_absent=0" target="_blank" rel="noreferrer">Book Now</a>
              </div>
            </div>
          </div>
          <div class="w-[400px] flex flex-col mt-6 mx-auto">
            <div class="shadow-sm rounded-xl bg-neutral-900 border-neutral-700 shadow-neutral-700/70">
              <img class="w-full h-auto rounded-t-xl" src={gifts} alt="Customized Gifts" />
              <div class="p-4 md:p-5">
                <h3 class="text-xl font-bold text-amber-500 dark:text-white">Customized Gifts</h3>
                <p class="mt-1 text-gray-300 text-md dark:text-neutral-400">We make customized gifts for your loved ones.</p>
                <a class="inline-flex rounded-lg bg-amber-400 px-4 py-1.5 mt-4 text-base font-semibold leading-7 text-black shadow-md shadow-amber-700  hover:bg-amber-600" href="https://api.whatsapp.com/send/?phone=8682960528&amp;text&amp;type=phone_number&amp;app_absent=0" target="_blank" rel="noreferrer">Book Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
