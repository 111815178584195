import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div id='privacy'>
            <div>
                <div className="min-h-screen bg-gray-100">
                    <main className="container mx-auto px-4 py-8">

                        <div className="container mx-auto py-16">
                            <h1 className="text-3xl font-bold text-center">Privacy Policy</h1>
                        </div>

                        <section className="mb-8">
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                                SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?
                            </h2>
                            <p className="text-gray-600 mb-4">
                                When you purchase something from our store, as part of the buying and selling process, we collect the
                                personal information you give us such as your name, address, and email address.
                            </p>
                            <p className="text-gray-600 mb-4">
                                When you browse our store, we also automatically receive your computer’s internet protocol (IP) address in
                                order to provide us with information that helps us learn about your browser and operating system.
                            </p>
                            <p className="text-gray-600">
                                Email marketing (if applicable): With your permission, we may send you emails about our store, new products,
                                and other updates.
                            </p>
                        </section>

                        <section className="mb-8">
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4">SECTION 2 - CONSENT</h2>
                            <h3 className="text-xl text-gray-700 font-medium mb-2">How do you get my consent?</h3>
                            <p className="text-gray-600 mb-4">
                                When you provide us with personal information to complete a transaction, verify your credit card, place an
                                order, arrange for a delivery, or return a purchase, we imply that you consent to our collecting it and
                                using it for that specific reason only.
                            </p>
                            <p className="text-gray-600">
                                If we ask for your personal information for a secondary reason, like marketing, we will either ask you
                                directly for your expressed consent or provide you with an opportunity to say no.
                            </p>
                            <h3 className="text-xl text-gray-700 font-medium mb-2 mt-4">How do I withdraw my consent?</h3>
                            <p className="text-gray-600">
                                If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the
                                continued collection, use, or disclosure of your information, at any time, by contacting us at{' '}
                                <a href="mailto:contact@eventbee.in" className="text-blue-600 underline">
                                    contact@eventbee.in
                                </a>
                            </p>
                        </section>

                        <section className="mb-8">
                            <p className="text-gray-600">We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service.</p>
                        </section>

                        <section className='mb-8'>
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4">SECTION 4 - PAYMENT</h2>
                            <p className="text-gray-600 mb-4">We use Razorpay for processing payments. We/Razorpay do not store your card data on their servers. The data is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS) when processing payment. Your purchase transaction data is only used as long as is necessary to complete your purchase transaction. After that is complete, your purchase transaction information is not saved.</p>
                            <p className="text-gray-600 mb-4">Our payment gateway adheres to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover.</p>
                            <p className="text-gray-600 mb-4">PCI-DSS requirements help ensure the secure handling of credit card information by our store and its service providers.
                            </p>
                            <p className="text-gray-600 mb-4">For more insight, you may also want to read terms and conditions of razorpay on <a href="mailto:contact@eventbee.in" className="text-blue-600 underline">https://razorpay.com</a>
                            </p>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4">SECTION 5 - THIRD-PARTY SERVICES</h2>
                            <p className="text-gray-600 mb-4">In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us.
                            </p>
                            <p className="text-gray-600 mb-4">However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information we are required to provide to them for your purchase-related transactions.
                            </p>
                            <p className="text-gray-600 mb-4">For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers.
                            </p>
                            <p className="text-gray-600 mb-4">In particular, remember that certain providers may be located in or have facilities that are located a different jurisdiction than either you or us. So if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located.
                            </p>
                            <p className="text-gray-600 mb-4">Once you leave our store’s website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our website’s Terms of Service.
                            </p>
                            <h3 className="text-xl text-gray-700 font-medium mb-2 mt-4">Links</h3>
                            <p className="text-gray-600 mb-4">
                                When you click on links on our store, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.
                            </p>
                        </section>

                        <section>
                            <h2 className='text-2xl font-semibold text-gray-800 mb-4'>SECTION 6 - SECURITY</h2>
                            <p className="text-gray-600 mb-4">To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.</p>
                        </section>

                        <section>
                            <h2 className='text-2xl font-semibold text-gray-800 mb-4'>SECTION 7 - COOKIES</h2>
                            <p className="text-gray-600 mb-4">We use cookies to maintain session of your user. It is not used to personally identify you on other websites.</p>
                        </section>

                        <section>
                            <h2 className='text-2xl font-semibold text-gray-800 mb-4'>SECTION 8 - AGE OF CONSENT</h2>
                            <p className="text-gray-600 mb-4"> By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.</p>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4">SECTION 9 - CHANGES TO THIS PRIVACY POLICY</h2>
                            <p className="text-gray-600 mb-4">We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.
                            </p>
                            <p className="text-gray-600 mb-4">If our store is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to sell products to you.
                            </p>
                        </section>

                        <section>
                            <h2 className='text-2xl font-semibold text-gray-800 mb-4'>QUESTIONS AND CONTACT INFORMATION</h2>
                            <p className="text-gray-600 mb-4">If you would like to: access, correct, amend or delete any personal information we have about you, register a complaint, or simply want more information contact our Privacy Compliance Officer at  <a href="mailto:contact@eventbee.in" className="text-blue-600 underline">contact@eventbee.in</a></p>
                        </section>

                        <section className="mb-8">
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Refund Policy</h2>
                            <h3 className="text-xl text-gray-800 font-medium mb-2">Booking and Payments</h3>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li>Clients are required to pay 50% of the total event cost at the time of booking.</li>
                                <li>Bookings will be confirmed only upon receipt of the advance payment.</li>
                            </ul>
                            <h3 className="text-xl text-gray-800 font-medium mb-4">Cancellation Policy</h3>
                            <p className="text-gray-600 mb-4">
                                <strong>Cancellations Before 20 Days:</strong> Partial refunds will be provided after deducting non-recoverable expenses.
                            </p>
                            <p className="text-gray-600 mb-4">
                                <strong>Cancellations Within 20 Days:</strong> Advance payments will be non-refundable.
                            </p>
                            <p className="text-gray-600">
                                <strong>No Refunds for Last-Minute Cancellations:</strong> Cancellations made less than 7 days before the
                                event will not qualify for any refund.
                            </p>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Refund Process</h2>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li>If eligible for a refund, the refund will be processed within 14 business days after approval.
                                </li>
                                <li>Refunds will be credited to the original method of payment or as agreed upon.</li>
                            </ul>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4"> Non-Refundable Services</h2>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li>Custom services and expenses, such as venue bookings, vendor payments, or materials purchased specifically for the event, are non-refundable.

                                </li>
                                <li>Once the event setup begins, no refunds will be processed for any cancellations.</li>
                            </ul>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Dispute Resolution</h2>
                            <p className="text-gray-600 mb-4">If you have concerns regarding your refund, please contact us at [Your Contact Email]. We will address your issue promptly and aim to resolve it amicably.
                            </p>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Changes to the Policy
                            </h2>
                            <p className="text-gray-600 mb-4">This Refund Policy is subject to change. The terms at the time of booking will apply to your agreement.</p>
                        </section>
                    </main>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
