import React from "react";

const WhatsAppIcon = () => {
  return (
    <a
      href="https://api.whatsapp.com/send/?phone=8682960528&text&type=phone_number&app_absent=0"
      className="float"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fa fa-whatsapp my-float"></i>
    </a>
  );
};

export default WhatsAppIcon;
