import React from "react";
import birthday from '../Assests/images/birthday.jpeg'
import wedding from '../Assests/images/wedding.jpg'
import babyshower from '../Assests/images/baby-shower.webp'
import housewarming from '../Assests/images/house-warming.jpeg'
import corporate from '../Assests/images/corporate.jpg'
import sport from '../Assests/images/sports.jpeg'
import musicEvents from '../Assests/images/music-event.jpg'
import culturals from '../Assests/images/cultural.jpg'
import educational from '../Assests/images/educational.jpg'
import networking from '../Assests/images/networking.jpeg'
import collegeEvents from '../Assests/images/college.jpeg'
import charity from '../Assests/images/charity.jpeg'


const OurEvents = () => {
  return (
    <div className="h-screen" id="events">
      <div className="max-w-[1240px] mx-auto px-6">
        <h2 className="text-4xl md:text-5xl font-bold tracking-tight text-amber-400">Our Events</h2>
        <p className="mt-4 text-lg text-gray-300">Explore a variety of events that we organize, from birthdays and weddings to corporate and cultural events. Each event is crafted with attention to detail to ensure an unforgettable experience.</p>
      </div>

      <div className="hidden md:block">
        <div className="honeycomb">
          <li className="honeycomb-cell">
            <img
              className="honeycomb-cell_img"
              src={birthday}
              alt="Birthdays"
            />
            <div class="honeycomb-cell_title">Birthdays</div>
          </li>
          <li class="honeycomb-cell">
            <img
              className="honeycomb-cell_img"
              src={wedding}
              alt="Weddings"
            />
            <div class="honeycomb-cell_title">Weddings</div>
          </li>
          <li class="honeycomb-cell">
            <img
              className="honeycomb-cell_img"
              src={babyshower}
              alt="Baby Shower"
            />
            <div class="honeycomb-cell_title">Baby Shower</div>
          </li>
          <li class="honeycomb-cell">
            <img
              className="honeycomb-cell_img"
              src={housewarming}
              alt="House Warming"
            />
            <div class="honeycomb-cell_title">House Warming</div>
          </li>
          <li class="honeycomb-cell">
            <img
              class="honeycomb-cell_img"
              src={corporate}
              alt="corporate-events"
            />
            <div class="honeycomb-cell_title">Corporate Events
            </div>
          </li>
        </div>

        <div className="honeycomb">
          <li class="honeycomb-cell">
            <img
              class="honeycomb-cell_img"
              src={musicEvents}
              alt="music-events"
            />
            <div class="honeycomb-cell_title">Music Events
            </div>
          </li>
          <li class="honeycomb-cell">
            <img
              class="honeycomb-cell_img"
              src={sport}
              alt="sports-events"
            />
            <div class="honeycomb-cell_title">Sports Events</div>
          </li>

          <li class="honeycomb-cell honeycomb_Hidden"></li>
          <li class="honeycomb-cell">
            <img
              class="honeycomb-cell_img"
              src={culturals}
              alt="cultural-events"
            />
            <div class="honeycomb-cell_title">Cultural Events</div>
          </li>
          <li class="honeycomb-cell">
            <img
              class="honeycomb-cell_img"
              src={charity}
              alt="charity-events"
            />
            <div class="honeycomb-cell_title">Charity Events</div>
          </li>
        </div>

        <div className="honeycomb">
          <li class="honeycomb-cell">
            <img
              class="honeycomb-cell_img"
              src={educational}
              alt="educational-workshops"
            />
            <div class="honeycomb-cell_title">Educational Workshop</div>
          </li>
          <li class="honeycomb-cell">
            <img
              class="honeycomb-cell_img"
              src={collegeEvents}
              alt="college-events"
            />
            <div class="honeycomb-cell_title">College Events</div>
          </li>
          <li class="honeycomb-cell">
            <img
              class="honeycomb-cell_img"
              src={networking}
              alt="networking-events"
            />
            <div class="honeycomb-cell_title">Networking Events</div>
          </li>
        </div>
      </div>
      {/* mobile view */}
      <div className="block md:hidden">
        <div class="grid grid-cols-2 gap-3 p-4 justify-center items-center mx-auto py-8 transform translate-y-8">
          <div class="relative flex justify-center cursor-pointer">
            <img src={wedding} alt="Weddings" class="w-full h-[220px] rounded-md " />
            <div class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-60 opacity-0 transition-opacity duration-300 hover:opacity-100">
              <span class="text-amber-500 uppercase mt-4 text-center text-xl font-semibold">Weddings</span>
            </div>
          </div>
          <div class="relative flex justify-center cursor-pointer">
            <img src={birthday} alt="Birthdays" class="w-full h-[220px] rounded-md " />
            <div class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-60 opacity-0 transition-opacity duration-300 hover:opacity-100">
              <span class="text-amber-500 uppercase mt-4 text-center text-xl font-semibold">Birthdays</span>
            </div>
          </div>
          <div class="relative flex justify-center cursor-pointer">
            <img src={babyshower} alt="Baby Shower" class="w-full h-[220px] rounded-md " />
            <div class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-60 opacity-0 transition-opacity duration-300 hover:opacity-100">
              <span class="text-amber-500 uppercase mt-4 text-center text-xl font-semibold">Baby Shower</span>
            </div>
          </div>
          <div class="relative flex justify-center cursor-pointer">
            <img src={housewarming} alt="House Warming" class="w-full h-[220px] rounded-md" />
            <div class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-60 opacity-0 transition-opacity duration-300 hover:opacity-100">
              <span class="text-amber-500 uppercase mt-4 text-center text-xl font-semibold">House Warming</span>
            </div>
          </div>
          <div class="relative flex justify-center cursor-pointer">
            <img src={musicEvents} alt="Music Events" class="w-full h-[220px] rounded-md " />
            <div class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-60 opacity-0 transition-opacity duration-300 hover:opacity-100">
              <span class="text-amber-500 uppercase mt-4 text-center text-xl font-semibold">Music Events</span>
            </div>
          </div>
          <div class="relative flex justify-center cursor-pointer">
            <img src={networking} alt="Networking Events" class="w-full h-[220px] rounded-md" />
            <div class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-60 opacity-0 transition-opacity duration-300 hover:opacity-100">
              <span class="text-amber-500 uppercase mt-4 text-center text-xl font-semibold">Networking Events</span>
            </div>
          </div>
          <div class="relative flex justify-center cursor-pointer">
            <img src={charity} alt="Charity Events" class="w-full h-[220px] rounded-md" />
            <div class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-60 opacity-0 transition-opacity duration-300 hover:opacity-100">
              <span class="text-amber-500 uppercase mt-4 text-center text-xl font-semibold">Charity Events</span>
            </div>
          </div>
          <div class="relative flex justify-center cursor-pointer">
            <img src={culturals} alt="Cultural Events" class="w-full h-[220px] rounded-md" />
            <div class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-60 opacity-0 transition-opacity duration-300 hover:opacity-100">
              <span class="text-amber-500 uppercase mt-4 text-center text-xl font-semibold">Cultural Events</span>
            </div>
          </div>
          <div class="relative flex justify-center cursor-pointer">
            <img src={corporate} alt="Corporate Events" class="w-full h-[220px] rounded-md" />
            <div class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-60 opacity-0 transition-opacity duration-300 hover:opacity-100">
              <span class="text-amber-500 uppercase mt-4 text-center text-xl font-semibold">Corporate Events</span>
            </div>
          </div>
          <div class="relative flex justify-center cursor-pointer">
            <img src={educational} alt="Educational Workshop" class="w-full h-[220px] rounded-md" />
            <div class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-60 opacity-0 transition-opacity duration-300 hover:opacity-100">
              <span class="text-amber-500 uppercase mt-4 text-center text-xl font-semibold">Educational Workshop</span>
            </div>
          </div>
          <div class="relative flex justify-center cursor-pointer">
            <img src={collegeEvents} alt="College Events" class="w-full h-[220px] rounded-md" />
            <div class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-60 opacity-0 transition-opacity duration-300 hover:opacity-100">
              <span class="text-amber-500 uppercase mt-4 text-center text-xl font-semibold">College Events</span>
            </div>
          </div>
          <div class="relative flex justify-center cursor-pointer">
            <img src={sport} alt="Sports Events" class="w-full h-[220px] rounded-md " />
            <div class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-60 opacity-0 transition-opacity duration-300 hover:opacity-100">
              <span class="text-amber-500 uppercase mt-4 text-center text-xl font-semibold">Sports Events</span>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};
export default OurEvents;