import React from "react";
import mainLogo from "../Assests/EVENTBEE.png";
import { IoLocationOutline } from "react-icons/io5";

const Footer = () => {
    return (
        <footer class="bg-gradient-to-b from-[#eec33d] to-amber-500">
            <div class="mx-auto w-full p-4 py-6 lg:py-8">
                <div class="grid grid-cols-1 md:grid-cols-5 gap-2 md:gap-0">

                    <div class="flex items-center justify-between">
                        <a href="#home" class="flex items-center">
                            <img src={mainLogo} class="h-20 w-40" alt="Logo" />
                        </a>
                    </div>

                    <div className="col-span-1 md:col-span-2">
                        <h2 class="mb-2 text-sm font-semibold text-gray-900 uppercase px-6">Address</h2>
                        <div class="flex items-start space-x-2 text-white">
                            <IoLocationOutline className="text-2xl text-black" />
                            <div>
                                <span class="block text-black font-semibold">EVENT BEE ENTERTAINMENTS PRIVATE LIMITED</span>
                                <span class="block">
                                    17, Bhagyam Green Meadows,<br /> Annai Indra Nagar, Thiruvalluvar Street, Okkiyamthoraipakkam-600097
                                </span>
                            </div>
                        </div>
                    </div>

                    <div>
                        <h2 class="mb-2 text-sm font-semibold text-gray-900 uppercase">Contact</h2>
                        <div class="flex flex-col ">
                            <div class="flex items-center space-x-2 text-white">
                                <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4 text-black" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                                </svg>
                                <div class="whitespace-nowrap">+91-9025128482 <br /> +91-8682960528</div>
                            </div>
                            <div class="flex items-center space-x-2 text-white">
                                <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4 text-black" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                <div class="underline">contact@eventbee.in</div>
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-2">
                        <div>
                            <h2 class="mb-2 text-sm font-semibold text-gray-900 uppercase">Resources</h2>
                            <ul class="text-white">
                                <li class="mb-2 md:mb-4">
                                    <a href="#events" class="hover:underline">Events</a>
                                </li>
                                <li>
                                    <a href="#gallery" class="hover:underline">Gallery</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 class="mb-2 text-sm font-semibold text-gray-900 uppercase">Legal</h2>
                            <ul class="text-white ">
                                <li class="mb-2 md:mb-4">
                                    <a href="/privacy" class="hover:underline">Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="/terms" class="hover:underline">Terms & Conditions</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <hr class="my-2 border-black sm:mx-auto lg:my-8" />

                <div class="flex flex-col md:flex-row gap-1 md:gap-4">
                    <div class="flex sm:justify-center sm:mt-0 space-x-4">
                        <a href="/" class="text-gray-900 hover:text-gray-900">
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" class="w-5 h-5" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                            </svg></a>

                        <a href="https://www.instagram.com/eventbee99/?igsh=MXdoZWZ6dm1udGJiZQ%3D%3D&ig_mid=645A8065-217B-4488-B5E5-E802CB661084&utm_source=igweb" class="text-gray-900 hover:text-gray-900">
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" class="w-5 h-5" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg></a>
                    </div>
                    <div class="text-sm text-gray-600 sm:text-center">Copyright © 2024 <a href="/" class="hover:underline">EventBee™</a>. Designed by <a href="https://namuvi.com/" class="underline whitespace-nowrap">NAMUVI Technologies</a></div>
                </div>
            </div>
        </footer>
    )
}
export default Footer