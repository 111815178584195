import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import mainLogo from "../Assests/EVENTBEE.png";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const handleScroll = () => {
    if (window.scrollY > window.innerHeight) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={`fixed top-0 w-full z-40 transition-colors duration-300 ${isScrolled ? "bg-amber-400" : "bg-black"}`}>
      <div className={`flex justify-between items-center h-20 max-w-[1240px] mx-auto px-4 ${isScrolled ? "text-black" : "text-white"} ${isScrolled ? "bg-amber-400" : "bg-black"}`}>
        <Link to="/"> <img src={mainLogo} alt="EventBee Logo" className="h-18 w-40" /></Link>
        <ul className="hidden md:flex font-bold text-xl cursor-pointer">
          <li className="p-4 tracking-wide hover:text-amber-600">
            <Link to="/">Home</Link> 
          </li>
          <li className="p-4 tracking-wide hover:text-amber-600">
            <a href="#services">Services</a> 
          </li>
          <li className="p-4 tracking-wide hover:text-amber-600">
            <a href="#events">Our Events</a> 
          </li>
          <li className="p-4 tracking-wide hover:text-amber-600">
            <a href="#about">About</a>
          </li>
          <li className="p-4 tracking-wide hover:text-amber-600">
            <a to="#contact">Contact</a> 
          </li>
        </ul>

        <div onClick={handleNav} className={`block md:hidden cursor-pointer ${isScrolled ? "text-black" : "text-amber-400"}`}>
          <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 18V20H6V18H18ZM21 11V13H3V11H21ZM18 4V6H6V4H18Z"></path>
          </svg>
        </div>

        <ul
          className={
            nav
              ? "fixed left-0 top-0 w-[60%] h-full text-[#eec33d] border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500"
              : "ease-in-out duration-500 fixed left-[-100%]"
          }
        >
          <img src={mainLogo} alt="EventBee Logo" className="p-4 h-20" />
          <li className="p-5 border-b border-gray-600">
            <Link to="/" onClick={handleNav}>Home</Link> 
          </li>
          <li className="p-5 border-b border-gray-600">
            <a href="#services" onClick={handleNav}>Services</a> 
          </li>
          <li className="p-5 border-b border-gray-600">
            <a href="#events" onClick={handleNav}>Our Events</a> 
          </li>
          <li className="p-5 border-b border-gray-600">
            <a href="#about" onClick={handleNav}>About</a> 
          </li>
          <li className="p-5">
            <a href="#contact" onClick={handleNav}>Contact</a> 
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
