import React from 'react';
import homeImg from '../Assests/images/Home.jpg';
import Services from './Services';
import OurEvents from './OurEvents';
import About from './About';
import Contact from './Contact';

const Home = () => {
  return (
    <div>
      <section id="home">
        <main
          className="h-screen bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${homeImg})` }}
        >
          <div className="flex items-center justify-center h-full">
            <div className="p-4 md:p-8 flex flex-col justify-center items-center text-white text-center ">
              <h1
                className="text-6xl md:text-8xl uppercase text-shadow-lg  shadow-gray-950"
                style={{ fontFamily: "'Roboto Slab', serif" }}
              >
                Event BEE
              </h1>
              <p
                className="text-3xl md:text-4xl text-shadow-lg shadow-gray-950"
                style={{ fontFamily: "'Roboto Slab', serif" }}
              >
                Celebrate Your Moments
              </p>
            </div>
          </div>
        </main>
      </section>
      <Services/>
      <OurEvents/>
      <About/>
      <Contact/>
    </div>

  );
};

export default Home;
