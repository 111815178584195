import React from "react";
import about1 from '../Assests/images/about-1.jpg'
import about2 from '../Assests/images/about-2.jpg'
import about3 from '../Assests/images/about-3.jpg'
import about4 from '../Assests/images/about-4.jpg'


const About = () => {
  return (
    <div className="min-h-screen w-max-[1240px] mx-auto mt-[1100px] md:mt-[280px]" id="about">
      <div className="p-4 md:p-0">
        <div className="md:w-[1200px] mx-auto p-4 md:p-8 bg-amber-400 rounded-2xl">
          <h1 className="text-4xl md:text-5xl font-bold tracking-tight text-amber-900 text-center md:text-left">About Us</h1>
          <p className="mt-4 text-md md:text-lg text-center md:text-left text-white">
            We are a young and dynamic team of passionate event engineers with broad knowledge and expertise in event management. Based in Chennai, we specialize in creating memorable experiences through meticulous planning and flawless execution.
          </p>
        </div>
      </div>
      <section className="text-gray-300">
        <div className=" container flex flex-col gap-3 md:gap-10 justify-center p-6 mx-auto sm:py-12 lg:py-24 lg:flex-row lg:justify-between">
          <div className="flex flex-col justify-center p-3 md:p-6 text-center rounded-sm lg:max-w-md lg:text-left">
            <h1 className="text-5xl font-bold leading-none">Who are <span className="text-[#ea9d3e]">We?</span></h1>
            <p className="mt-4">At Event Bee, we pride ourselves on our innovative approach to event management. Our team has successfully executed a variety of social events, including birthdays, weddings, baby showers, house warmings, and corporate events. </p>
            <p className="mt-4">Our commitment to excellence and attention to detail ensures that every event we handle is a resounding success.</p>
            <p className="mt-4">Whether it's a live music event, a sports meet, or a corporate gathering, we have the skills, experience, and network to make your vision come to life.</p>
            <p className="mt-4">Our mission is to exceed your expectations and create events that are not only successful but also unforgettable.</p>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <div className="grid gap-2">
              <div >
                <img src={about1} alt="" className="h-auto w-[400px] border border-amber-600 rounded-lg" />
              </div>
              <div >
                <img src={about2} alt="" className="h-auto w-[400px] border border-amber-600 rounded-lg" />
              </div>
            </div>
            <div className="grid gap-2">

              <div >
                <img src={about4} alt="" className="h-auto w-[400px] border border-amber-600 rounded-lg" />
              </div>
              <div >
                <img src={about3} alt="" className="h-auto w-[400px] border border-amber-600 rounded-lg" />
              </div>

            </div>

          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
