import React, { useState } from "react";
import contactBg from '../Assests/images/contact-bg.jpg'

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    email: "",
    message: "",
  });
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const sendToWhatsApp = () => {
    const { name, contact, email, message } = formData;
    const whatsappNumber = "8682960528";
    const text = `Name: ${name}\nContact: ${contact}\nEmail: ${email}\nMessage: ${message}`;
    const url = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
      text
    )}`;
    window.open(url, "_blank");
  };
  
  return (
    <section id="contact" className="contact-section">
      <div
        className="w-full min-h-screen backdrop-blur-sm flex flex-col justify-center items-center z-1"
        style={{ backgroundImage: `url(${contactBg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
      >
        <h1 className="text-5xl font-bold text-center text-black mb-18 bg-white bg-opacity-50 rounded-xl p-3">Contact Us</h1>
        <div className="flex md:hidden">
          <div className="mt-4 flex gap-4 p-3 font-lg font-bold text-black bg-white bg-opacity-70 rounded-xl md:p-3 space-x-1 md:space-x-3">
            <div>
              <p>For Bookings</p>
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="flex w-4 h-4 mt-1 ml-9 font-bold"
                aria-hidden="true"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
              </svg>
            </div>
            <div>
              <ul>
                <li>+91-9025128482</li>
                <li>+91-8682960528</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="hidden md:flex">
          <div className="mt-4 flex font-lg font-bold text-black bg-white bg-opacity-70 rounded-xl md:p-3 space-x-1 md:space-x-3">
            <p>For Bookings</p>
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth="2"
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="flex w-4 h-4 mt-1 font-bold"
              aria-hidden="true"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
            </svg>
            <span>+91-9025128482</span>
            <span>||</span>
            <span>+91-8682960528</span>
          </div>
        </div>

        <div className="bg-transparent text-gray-900 px-4 sm:px-6 lg:px-8 w-full">
          <div className="flex flex-col mt-6 w-full max-w-[600px] mx-auto space-y-5">
            <input
              className="w-full px-6 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
              type="text"
              placeholder="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            <input
              className="w-full px-6 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
              type="number"
              placeholder="Contact number"
              name="contact"
              value={formData.contact}
              onChange={handleChange}
            />
            <input
              className="w-full px-6 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
              type="email"
              placeholder="Your email-id"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <input
              className="w-full px-6 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
              type="text"
              placeholder="Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
            />
            <button
              className="tracking-wide text-lg font-semibold bg-amber-500 text-gray-100 py-2 rounded-lg hover:bg-amber-600 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
              onClick={sendToWhatsApp}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
